$primaries: (
  "magenta": theme('colors.primary.magenta'),
  "gray": theme('colors.primary.gray'),
  "lgray": theme('colors.primary.lgray'),
);

// * MIXINS *
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// Usage as a mixin
.clearfix {
  @include clearfix;
}

@mixin custom-flex-row {
  @apply
    flex-row
    flex
    justify-between
    content-center;
}

@mixin custom-flex-item {
  @apply
    flex;

    * {
      @apply
        self-center;
    }
}

@mixin center-content {
  @apply
    flex
    items-center
    justify-center;
}

@mixin no-focus {
  @apply
    shadow-none
    outline-none;

    &:focus {
      @apply
        shadow-none
        outline-none;
    }
}

@mixin button {
  @apply
    rounded-lg
    py-1
    px-4
    text-center
    uppercase
    text-sm
    border-none;

  letter-spacing: .05em;
}

.btn {
  @include button;
  @include no-focus;

  @apply
    basic-transition;

  &:hover {
    @apply
      shadow-md
      no-underline;
  }

  &.primary-btn {
    @apply
      rounded-full
      bg-primary-magenta
      text-white
      w-full
      px-4
      py-2;

    min-width: 10rem;

    > * {
      @apply
        text-white;
    }

    @screen sm {
      @apply
        w-auto
        py-4;

        min-width: 15rem;
    }

    &:hover {        
      box-shadow: 0 0 8px #DB003D;
    }
  }
}

.btn-outline {
  @extend .btn;
}

.flex-wrapper {
  @screen sm {
    @apply
      flex
      flex-wrap
      items-center;

    .flex-item {
      @apply
        w-1/2;
    }
  }
}

.floating-1 {
  animation: float1 7s linear infinite;

  @apply
    basic-transition;

  @keyframes float1 {
    0% {
      -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    }
    50% {
      -webkit-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
            transform: rotate(-3deg);
    }
    100% {
      -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    }
  }
}

.floating-2 {
  animation: float2 7s linear infinite;

  @apply
    basic-transition;

  @keyframes float2 {
    0% {
      -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    }
    50% {
      -webkit-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
            transform: rotate(3deg);
    }
    100% {
      -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    }
  }
}

.rotating-1 {
  animation: rotating1 60s linear infinite;

  @apply
    basic-transition;

  @keyframes rotating1 {
    0% {
      -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    }
    50% {
      -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
            transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    }
  }
}

.sunny {
  animation: scaling1 5s linear infinite;

  @apply
    basic-transition;

  @keyframes scaling1 {
    0% {
      -webkit-transform: scale(1) rotate(0) translate(0);
        -ms-transform: scale(1) rotate(0) translate(0);
            transform: scale(1) rotate(0) translate(0);
    }
    50% {
      -webkit-transform: scale(1.1) rotate(5deg) translate(5px, 5px);
        -ms-transform: scale(1.1) rotate(5deg) translate(5px, 5px);
            transform: scale(1.1) rotate(5deg) translate(5px, 5px);
    }
    100% {
      -webkit-transform: scale(1) rotate(0) translate(0);
        -ms-transform: scale(1) rotate(0) translate(0);
            transform: scale(1) rotate(0) translate(0);
    }
  }
}

@each $name, $hexa in $primaries {
  .btn-#{$name} {
    background-color: #{$hexa};
    border-color: #{$hexa};

    &:hover {
      @apply
        opacity-75;
    }
  }

  .btn-outline-#{$name} {
    border-color: #{$hexa};
    color: #{$hexa};

    &:hover {
      background-color: #{$hexa};

      @apply
        text-primary-magenta;
    }
  }
}

@mixin goTo {
  @apply
    tracking-0.5
    text-gray
    text-xxs
    uppercase
    font-thin
    basic-transition;
}