.page-view.not-found {
  @apply
    relative
    pb-10
    pt-10
    overflow-hidden;

  > .container {
    @apply
      mx-auto;
  }
  
  h1 {
    @apply
      text-3xl
      font-bold
      mb-10
      mt-10
      text-center;

      @screen sm {
        @apply
          text-5xl;
      }
  }

  .bg-image {
    @apply
      opacity-25
      my-10
      inline-block
      max-w-full;

    width: 17.5rem;

    // @screen sm {
    //   opacity: 0.035;
    // }
  }

  .back-to {
    @apply
      mt-10
      text-primary-magenta
      basic-transition;

    &:hover {
      @apply
        text-primary-gray;
    }
  }
}